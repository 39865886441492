import {
  createUserWithGroupRequest,
  createUserOnObjectWithGroupRequest,
  deleteUserByIdRequest,
  getOrganizationUserRequest,
  getUserByIdRequest,
  getUsersRequest,
  patchUserByIdRequest,
  createUserRequest,
  postAddUserToGroupRequest,
  getObjectUsersRequest,
  patchUserPhoneByIdRequest,
  postDeleteUserFromGroupRequest,
  getUsersByAccessGroup,
  uploadUserFileRequest,
  postAddUserToFreeTrialRequest,
  getAccesses,
  postChangeUserToGroupRequest,
  getUserObjecByIdRequest,
  getUsersObjectsClearRequest,
  patchUserOnObjectByIdRequest,
  getKindergartensUserRequest,
  postAddUserKindergartensToGroupRequest,
  getUserKindergartensByIdRequest,
  postAddPersonnelKindergtensToGroupRequest,
  patchUserOnKindergartensByIdRequest
} from "@/helpers/api/user";
import { deleteArrUsersRequest } from "@/helpers/api/groups";
import { getAllUsersClearRequest } from "@/helpers/api/user";

import Vue from "vue";
import router from "@/router";
import { getRoleGroupsRequest } from "@/helpers/api/role-groups";
import Axios from "axios";
import {
  getChildrenGroupByIdRequest,
  patchChildrenGroupByIdRequest
} from "@/helpers/api/childrenGroup";
import { csvExport } from "@/helpers/CsvHelper";
import { getPersonnelKindergartensRequest } from "@/helpers/api/kindergartens";
import { getIdentificatorUserRole } from "../../../helpers/Constants/roles";

const state = {
  pageUsersList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  objectUsersList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  organizationUsersList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  createUserOnObjectForm: {
    phone_number: "",
    group: null,
    user: "",
    house: "",
    houseNumber: "",
    carNumber: "",
    comments: ""
  },
  createUserForm: {
    selectedId: null,
    phone_number: "",
    group: null,
    user: "",
    comments: ""
  },
  createUserFileForm: {
    file: null,
    group: null
  },
  userEditForm: {
    phone_number: { value: "", isEdit: false, id: null },
    user: { value: "", isEdit: false, id: null },
    comments: { value: "", isEdit: false, id: null },
    first_name: { value: "", isEdit: false },
    middle_name: { value: "", isEdit: false },
    last_name: { value: "", isEdit: false },
    is_phone_confirmed: { value: "", isEdit: false }
  },
  userEditOnObjectForm: {
    phone_number: { value: "", isEdit: false },
    user: { value: "", isEdit: false, id: null },
    house: { value: "", isEdit: false, id: null },
    houseNumber: { value: "", isEdit: false, id: null },
    carNumber: { value: "", isEdit: false, id: null },
    comments: { value: "", isEdit: false, id: null },
    is_phone_confirmed: { value: "", isEdit: false }
  },
  userInfo: {
    results: null
  },
  userAccesses: {
    results: null
  },
  personnelList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  personnelCreate: {
    phone_number: null,
    user: "",
    comments: "",
    selectedRole: 80,
    teacherGroup: ""
  },
  currentUserCreateObject: null,
  currentUserCreateKindergartens: {
    results: null
  },
  users: "",
  usersRole: "",
  userGroup: ""
};
const getters = {
  getPageUsersList: state => state.pageUsersList,
  getCreateUserForm: state => state.createUserForm,
  getCreateUserOnObjectForm: state => state.createUserOnObjectForm,
  getUserEditForm: state => state.userEditForm,
  getUserEditOnObjectForm: state => state.userEditOnObjectForm,
  getUserInfo: state => state.userInfo,
  getAccessesInfo: state => state.userAccesses,
  getObjectUsersList: state => state.objectUsersList,
  getOrganizationUsersList: state => state.organizationUsersList,
  getPersonnelList: state => state.personnelList,
  getPersonnelCreate: state => state.personnelCreate,
  getUsers: state => state.users,
  getUsersRole: state => state.usersRole,
  getUserGroup: state => state.userGroup,
  getCreateUserFileForm: state => state.createUserFileForm,
  getCreateCurrentUserObjects: state => state.currentUserCreateObject,
  getCreateCurrentUserKindergartens: state =>
    state.currentUserCreateKindergartens
};

const actions = {
  async freeTrial(context, id) {
    const userGroup = id; // Пользователи с бесплатным доступом к объекту

    return new Promise((resolve, reject) => {
      postAddUserToFreeTrialRequest({
        id: context.getters.getUserId,
        data: { group: userGroup }
      })
        .then(resolve => {
          router.go(0);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadUsersList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      // getUsersByAccessGroup
      getUsersRequest({
        query: { ...query, limit: 5000, offset: null },
        group_id: id
      })
        .then(response => {
          csvExport(response.data, header, "Пользователи");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadKindergartensUsersList(context, header) {
    const { query } = context.getters.getPageQuery;

    return new Promise((resolve, reject) => {
      // getUsersByAccessGroup
      getKindergartensUserRequest({
        query: { ...query, limit: 5000, offset: null },
        id: getIdentificatorUserRole()
      })
        .then(response => {
          csvExport(response.data, header, "Пользователи");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadKindergartensAllUsersList(context, header) {
    const { query } = context.getters.getPageQuery;

    const limit = 1000;
    let offset = 0;
    const allDataResult = [];
    const request = (
      await getKindergartensUserRequest({
        query: { ...query, limit, offset },
        id: getIdentificatorUserRole()
      })
    ).data;
    const pages = Array.apply(null, {
      length: Math.round(request.count / limit)
    }).map((el, index) => index + 1);
    const frstPage = request.results;
    allDataResult.push(frstPage);
    for (const page of pages) {
      offset = limit * page;
      const resultPerPage = (
        await getKindergartensUserRequest({
          query: { ...query, limit, offset },
          id: getIdentificatorUserRole()
        })
      ).data.results;
      allDataResult.push(resultPerPage);
    }
    const data = {
      ...request,
      results: allDataResult.flat(Infinity)
    };

    let preheader = header.filter(el => {
      return el.value !== "last_name" && el.text !== "ФИО";
    });
    return new Promise(resolve => {
      resolve(csvExport(data, preheader, "Пользователи"));
    });
  },
  async downloadOrganizationUsersList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    const limit = 1000;
    let offset = 0;
    const allDataResult = [];
    const request = (
      await getOrganizationUserRequest({
        query: { ...query, limit, offset },
        id
      })
    ).data;
    const pages = Array.apply(null, {
      length: Math.round(request.count / limit)
    }).map((el, index) => index + 1);
    const frstPage = request.results;
    allDataResult.push(frstPage);
    for (const page of pages) {
      offset = limit * page;
      const resultPerPage = (
        await getOrganizationUserRequest({
          query: { ...query, limit, offset },
          id
        })
      ).data.results;
      allDataResult.push(resultPerPage);
    }
    const data = {
      ...request,
      results: allDataResult.flat(Infinity)
    };

    let preheader = header.filter(el => {
      return el.value !== "last_name" && el.text !== "ФИО";
    });
    return new Promise(resolve => {
      resolve(csvExport(data, preheader, "Пользователи"));
    });
  },

  async downloadkindergartenUsersList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    const limit = 1000;
    let offset = 0;
    const allDataResult = [];
    const request = (
      await getKindergartensUserRequest({
        query: { ...query, limit, offset },
        id
      })
    ).data;
    const pages = Array.apply(null, {
      length: Math.round(request.count / limit)
    }).map((el, index) => index + 1);
    const frstPage = request.results;
    allDataResult.push(frstPage);
    for (const page of pages) {
      offset = limit * page;
      const resultPerPage = (
        await getKindergartensUserRequest({
          query: { ...query, limit, offset },
          id
        })
      ).data.results;
      allDataResult.push(resultPerPage);
    }
    const data = {
      ...request,
      results: allDataResult.flat(Infinity)
    };

    let preheader = header.filter(el => {
      return el.value !== "last_name" && el.text !== "ФИО";
    });
    return new Promise(resolve => {
      resolve(csvExport(data, preheader, "Пользователи"));
    });
  },

  async clearPersonnel(context, { idChildrenGroup, teacherId }) {
    return new Promise((resolve, reject) => {
      getChildrenGroupByIdRequest({
        id: idChildrenGroup,
        query: {}
      })
        .then(response => {
          const teachers = response.data.teachers.filter(el => el != teacherId);
          patchChildrenGroupByIdRequest({
            id: idChildrenGroup,
            data: {
              ...response.data,
              teachers
            }
          }).finally(() => {
            resolve(response);
            router.go(-1);
          });
        })
        .catch(error => reject(error));
    });
  },
  async downloadPersonnelList(context, header) {
    const id = context.getters.getCurrentOrganization;
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getUsersRequest({
        query: { ...query, "organization-personal": id }
      })
        .then(response => {
          csvExport(response.data, header, "Сотрудники");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadKinderPersonnelList(context, header) {
    const id = context.getters.getCurrentOrganization;
    const { query } = context.getters.getPageQuery;

    const userList = (await getPersonnelKindergartensRequest({ id, query }))
      .data.results;

    csvExport({ results: userList }, header, "Персонал");
  },
  async downloadKindergartenPersonnelList(context, header) {
    const { id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getPersonnelKindergartensRequest({ id })
        .then(response => {
          csvExport(response.data, header, "Сотрудники");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadObjectsUsersList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    const limit = 1000;
    let offset = 0;
    const allDataResult = [];

    const request = (
      await getObjectUsersRequest({
        query: { ...query, limit, offset, groups__object: id },
        objId: id
      })
    ).data;
    const pages = Array.apply(null, {
      length: Math.round(request.count / limit)
    }).map((el, index) => index + 1);
    const frstPage = request.results;
    allDataResult.push(frstPage);
    for (const page of pages) {
      offset = limit * page;
      const resultPerPage = (
        await getObjectUsersRequest({
          query: { ...query, limit, offset, groups__object: id },
          objId: id
        })
      ).data.results;
      allDataResult.push(resultPerPage);
    }
    const data = {
      ...request,
      results: allDataResult.flat(Infinity)
    };
    return new Promise(resolve => {
      resolve(csvExport(data, header, "Пользователи"));
    });
  },

  async downloadPageUsersList(context, header) {
    const { query } = context.getters.getPageQuery;
    const limit = 1000;
    let offset = 0;
    const allDataResult = [];
    if ([30, 31].includes(context.getters.getCurrentRole)) {
      const request = (
        await getOrganizationUserRequest({
          query: {
            ...query,
            limit,
            offset,
            id: context.getters.getCurrentOrganization
          }
        })
      ).data;
      const pages = Array.apply(null, {
        length: Math.round(request.count / limit)
      }).map((el, index) => index + 1);
      const frstPage = request.results;
      allDataResult.push(frstPage);
      for (const page of pages) {
        offset = limit * page;
        const resultPerPage = (
          await getUsersRequest({
            query: { ...query, limit, offset }
          })
        ).data.results;
        allDataResult.push(resultPerPage);
      }
      const data = {
        ...request,
        results: allDataResult.flat(Infinity)
      };
      return new Promise(resolve => {
        resolve(csvExport(data, header, "Пользователи"));
      });
    } else {
      const request = (
        await getUsersRequest({
          query: { ...query, limit, offset }
        })
      ).data;
      const pages = Array.apply(null, {
        length: Math.round(request.count / limit)
      }).map((el, index) => index + 1);
      const frstPage = request.results;
      allDataResult.push(frstPage);
      for (const page of pages) {
        offset = limit * page;
        const resultPerPage = (
          await getUsersRequest({
            query: { ...query, limit, offset }
          })
        ).data.results;
        allDataResult.push(resultPerPage);
      }
      const data = {
        ...request,
        results: allDataResult.flat(Infinity)
      };
      let preheader = header.filter(el => {
        return el.value !== "last_name" && el.text !== "ФИО";
      });
      return new Promise(resolve => {
        resolve(csvExport(data, preheader, "Пользователи"));
      });
    }
  },
  async fetchPageUsersList(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;

    return new Promise((resolve, reject) => {
      getUsersRequest({ query: { ordering, ...query } })
        .then(response => {
          context.commit("setUsersList", {
            listName: "pageUsersList",
            users: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchPageKindergartnUsersGroupsList(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    const id = context.getters.getGroupInfo.results.organization;
    return new Promise((resolve, reject) => {
      getKindergartensUserRequest({ query: { ordering, ...query }, id })
        .then(response => {
          context.commit("setUsersList", {
            listName: "pageUsersList",
            users: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async fetchPageUsersObjectList(context, id) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;

    return new Promise((resolve, reject) => {
      getUsersObjectsClearRequest({ query: { ordering, ...query }, ...id })
        .then(response => {
          context.commit("setUsersList", {
            listName: "pageUsersList",
            users: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async fetchUsersGroupUserList(context) {
    const { query, id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getUsersByAccessGroup({ query, group_id: id })
        .then(response => {
          context.commit("setUsersList", {
            listName: "pageUsersList",
            users: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchObjectUsersList(context) {
    const { id, query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getObjectUsersRequest({
        query: { ...query, ordering, groups__object: id },
        objId: id
      })
        .then(response => {
          context.commit("setUsersList", {
            listName: "objectUsersList",
            users: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchOrganizationUsersList(context) {
    const { id, query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getOrganizationUserRequest({ query: { ...query, ordering }, id })
        .then(response => {
          context.commit("setUsersList", {
            listName: "organizationUsersList",
            users: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchKindergartensUsersList(context) {
    const { id, query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getKindergartensUserRequest({ query: { ...query, ordering }, id })
        .then(response => {
          context.commit("setUsersList", {
            listName: "organizationUsersList",
            users: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchPersonnelList(context) {
    const { id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getPersonnelKindergartensRequest({ id })
        .then(response => {
          context.commit("setUsersList", {
            listName: "personnelList",
            users: response.data
          });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  fetchUserAccesses(context) {
    const { id } = context.getters.getUserId;
    return new Promise((resolve, reject) => {
      getAccesses({ id })
        .then(response => {
          context.commit("setUserInfo", {
            listName: "userAccesses",
            users: response.data
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchUserInfo(context) {
    let { id } = context.getters.getPageQuery;
    if (!id) {
      id = context.getters.getUserId;
    }
    return new Promise((resolve, reject) => {
      getUserByIdRequest({ id })
        .then(response => {
          context.commit("setUserInfo", {
            listName: "userInfo",
            users: response.data
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchUserObjectInfo(context) {
    let { id } = context.getters.getPageQuery;
    if (!id) {
      id = context.getters.getUserId;
    }
    return new Promise((resolve, reject) => {
      getUserObjecByIdRequest({
        user_id: id,
        object_id: localStorage.getItem("ObjectId")
      })
        .then(response => {
          let clone = {}; // новый пустой объект
          for (let key in response.data) {
            if (key == "additional_fields") {
              clone[key] = response.data[key].sort(
                (current, next) => current.id - next.id
              );
            } else {
              clone[key] = response.data[key];
            }
          }
          context.commit("setUserInfo", {
            listName: "userInfo",
            users: clone
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async fetchAllUserInfo(context, { data }) {
    return new Promise((resolve, reject) => {
      getAllUsersClearRequest({
        query: {
          search: data.phone_number.replace(/[^\d+а-яА-ЯёЁ ]/g, "").slice(0, 25)
        }
      })
        .then(response => {
          const user = response.data.results;
          context.commit("setCurrentUser", { user });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchObjectUserInfo(context, { data }) {
    return new Promise((resolve, reject) => {
      getUsersObjectsClearRequest({
        id: context.getters.getObjectInfo.results.id,
        query: {
          search: data.phone_number.replace(/[^\d+а-яА-ЯёЁ ]/g, "").slice(0, 25)
        }
      })
        .then(response => {
          const user = response.data.results;
          context.commit("setCurrentUser", { user });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // async fetchKindergartenUserIdInfo(context ) {
  //   return new Promise((resolve, reject) => {
  //     getUserKindergartensByIdRequest({
  //       kindergardens_id: context.getters.getKindergartenId||context.getters.getCurrentOrganization,
  //       user_id: context.getters.getPageQuery.id,
  //     })
  //       .then(response => {
  //
  //         const user = response.data;
  //         context.commit("setUserInfo", { user });
  //         resolve();
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },
  fetchKindergartenUserIdInfo(context) {
    let { id } = context.getters.getPageQuery;
    if (!id) {
      id = context.getters.getUserId;
    }
    return new Promise((resolve, reject) => {
      getUserKindergartensByIdRequest({
        kindergardens_id: getIdentificatorUserRole(),
        user_id: context.getters.getPageQuery.id
      })
        .then(response => {
          const user = response.data;
          context.commit("setCurrentUserKindergardens", { user });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async fetchKindergartenUserInfo(context, { data }) {
    return new Promise((resolve, reject) => {
      getKindergartensUserRequest({
        id: getIdentificatorUserRole(),
        query: {
          phone_number: data.phone_number
            .replace(/[^\d+а-яА-ЯёЁ ]/g, "")
            .slice(0, 25)
        }
      })
        .then(response => {
          const user = response.data.results;
          context.commit("setCurrentUserKindergardens", { user });
          context.commit("setPersonnelCreate", { user });

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async clearUsersPage(context) {
    context.commit("clearUsersList", {
      listName: "pageUsersList"
    });
    return Promise.resolve();
  },

  async prepareEditUserOnObject(context, { id }) {
    const userInfo = (
      await getUserObjecByIdRequest({
        user_id: id,
        object_id: localStorage.getItem("ObjectId")
      })
    ).data;

    context.commit("prepareEditUserOnObject", userInfo);
  },

  async prepareEditUser(context, { id }) {
    const userInfo = (
      await getUserKindergartensByIdRequest({
        user_id: id,
        kindergardens_id: getIdentificatorUserRole()
      })
    ).data;
    context.commit("prepareEditUser", userInfo);
  },

  // async editUser(context, {id}) {
  //   const editForm = context.getters.getUserEditForm;
  //   let editedData = {};
  //   for (let key in editForm) {
  //     if (editForm[key].isEdit) {
  //       editedData[key] = editForm[key].value;
  //     }
  //   }
  //
  //   if (editForm.phone_number.isEdit) {
  //     await patchUserPhoneByIdRequest({
  //       id,
  //       data: {phone_number: editForm.phone_number.value}
  //     });
  //   }
  //
  //   const editProm = patchUserByIdRequest({id, data: editedData});
  //   editProm.then(() => {
  //     router.go(-1);
  //   });
  //   return editProm;
  // },

  //

  async prepareGeneralEditUser(context, { id }) {
    // const controllerInfo = context.getters.getControllerInfo.results;
    //
    const userInfo = (await getUserByIdRequest({ id })).data;
    context.commit("prepareEditUser", userInfo);
  },

  async editGeneralUser(context, { id }) {
    const editForm = context.getters.getUserEditForm;
    let editedData = {};
    for (let key in editForm) {
      if (editForm[key].isEdit) {
        editedData[key] = editForm[key].value;
      }
    }

    if (editForm.phone_number.isEdit) {
      await patchUserPhoneByIdRequest({
        id,
        data: { phone_number: editForm.phone_number.value }
      });
    }

    const editProm = patchUserByIdRequest({ id, data: editedData });
    editProm.then(() => {
      router.go(-1);
    });
    return editProm;
  },

  async editUser(context, { id }) {
    const editForm = context.getters.getUserEditForm;
    let editedData = [];
    for (let key in editForm) {
      const { isEdit, id, value } = editForm[key];
      if (isEdit) editedData.push({ id, value });
    }
    if (editForm.phone_number.isEdit) {
      await patchUserPhoneByIdRequest({
        id,
        data: { phone_number: editForm.phone_number.value }
      });
    }
    const editProm = patchUserOnKindergartensByIdRequest({
      kindergartensId: getIdentificatorUserRole(),
      user_id: id,
      data: { additional_fields: editedData }
    });
    editProm.then(() => {
      router.go(-1);
    });
    return editProm;
  },

  async editUserOnObject(context, { objId, id }) {
    const editForm = context.getters.getUserEditOnObjectForm;
    let editedData = [];
    for (let key in editForm) {
      const { isEdit, id, value } = editForm[key];
      if (isEdit) editedData.push({ id, value });
    }
    const editProm = patchUserOnObjectByIdRequest({
      object_id: objId,
      user_id: id,
      data: { additional_fields: editedData }
    });
    editProm.then(() => {
      router.go(-1);
    });
    return editProm;
  },

  async createUser(context) {
    const userForm = context.getters.getCreateUserForm;
    let prom;
    // prom = postAddUserKindergtensToGroupRequest({
    //   id: context.getters.getKindergartenId,
    //   data: {
    //     ...userForm,
    //     phone_number: userForm.phone_number
    //       .replace(/[^\d+]/g, "")
    //       .slice(0, 13)
    //   }
    // })
    prom = postAddUserKindergartensToGroupRequest({
      id: getIdentificatorUserRole(),
      data: {
        additional_fields: [
          {
            id: localStorage.getItem("UserFieldId"),
            value: userForm.user
          },
          {
            id: localStorage.getItem("CommentsFieldId"),
            value: userForm.comments
          }
        ],
        phone_number: userForm.phone_number.replace(/[^\d+]/g, "").slice(0, 13),
        group: userForm.group
      }
    });

    prom.then(() => {
      router.go(-1);
      context.commit("clearCreateUserForm");
    });
    return prom;
  },

  async createUserOnObject(context) {
    const userForm = context.getters.getCreateUserOnObjectForm;
    let prom;
    prom = createUserOnObjectWithGroupRequest({
      id: context.getters.getObjectInfo.results.id,
      data: {
        additional_fields: [
          {
            id: localStorage.getItem("UserFieldId"),
            value: userForm.user
          },
          {
            id: localStorage.getItem("HouseFieldId"),
            value: userForm.house
          },
          {
            id: localStorage.getItem("ApartmentFieldId"),
            value: userForm.houseNumber
          },
          {
            id: localStorage.getItem("CarNumberFieldId"),
            value: userForm.carNumber
          },
          {
            id: localStorage.getItem("CommentsFieldId"),
            value: userForm.comments
          }
        ],
        phone_number: userForm.phone_number.replace(/[^\d+]/g, "").slice(0, 13),
        group: userForm.group
      }
    });

    prom.then(() => {
      router.go(-1);
      context.commit("clearCreateUserForm");
    });
    return prom;
  },

  async vsCreateUser(context, { userId, userForm, groupId }) {
    let prom;
    if (!userId)
      prom = createUserWithGroupRequest({
        data: {
          ...userForm,
          phone_number: userForm.phone_number
            .replace(/[^\d+]/g, "")
            .slice(0, 13)
        }
      });
    else
      prom = postAddUserToGroupRequest({
        id: userId,
        data: { group: groupId }
      });

    return prom;
  },

  async vsCreateNewUser(context, { userForm }) {
    return new Promise(resolve => {
      createUserWithGroupRequest({
        data: {
          ...userForm,
          phone_number: userForm.phone_number
            .replace(/[^\d+]/g, "")
            .slice(0, 13)
        }
      }).then(response => {
        resolve(response.data);
      });
    });
  },

  async changeUserToGroup(context, userId) {
    return new Promise(resolve => {
      postChangeUserToGroupRequest({
        id: userId.data.id,
        kindergartens_pk: getIdentificatorUserRole(),
        data: { role: userId.data.group }
      }).then(() => {
        resolve();
      });
    });
  },

  async vsAddUserToGroup(context, { userId, groupId }) {
    return new Promise(resolve => {
      postAddUserToGroupRequest({
        id: userId,
        data: { group: groupId }
      }).then(() => {
        resolve();
      });
    });
  },

  async deleteServiceStaff(context, { id, service_org }) {
    const user = await getUserByIdRequest({ id });
    const groups = [...user.data.role_groups, ...user.data.access_groups];
    const promArr = [];
    groups.forEach(gr => {
      if (gr.organization == service_org)
        promArr.push(
          postDeleteUserFromGroupRequest({ id, data: { group: gr.id } })
        );
    });
    const prom = Promise.all(promArr);
    prom.finally(() => {
      router.go(-1);
    });
    return prom;
  },

  async deleteUser(context, { id }) {
    const prom = deleteUserByIdRequest({ id });
    prom.then(() => {
      router.go(-1);
    });
    return prom;
  },
  async banUser(context, { id }) {
    const prom = patchUserByIdRequest({ id, data: { is_active: false } });
    prom.then(() => {
      router.go(-1);
    });
    return prom;
  },
  async unBanUser(context, { id }) {
    const prom = patchUserByIdRequest({ id, data: { is_active: true } });
    prom.then(() => {
      router.go(-1);
    });
    return prom;
  },
  async createStuff(context, kindergartenId) {
    const personnelCreate = context.getters.getPersonnelCreate;
    const userPersonnel = context.getters.getUsers; // id пользователя
    const userGroup = context.getters.getUserGroup; // Пользователи с бесплатным доступом к объекту
    const teachers = personnelCreate.teacherGroup;
    const roleGroups = (
      await getRoleGroupsRequest({
        query: {
          organization: kindergartenId || context.getters.getCurrentOrganization
        }
      })
    ).data.results;

    const selectedGroup = roleGroups.find(
      el => el.role == personnelCreate.selectedRole
    );

    //если существующий пользователь
    if (userPersonnel) {
      await postAddUserToGroupRequest({
        id: userPersonnel,
        data: { group: selectedGroup.id }
      });

      await postAddUserToGroupRequest({
        id: userPersonnel,
        data: { group: userGroup }
      });

      if (teachers) {
        const existTeachers = (await Axios.get(`/children-groups/${teachers}/`))
          .data.teachers;
        return Axios.patch(`/children-groups/${teachers}/`, {
          teachers: [...existTeachers, userPersonnel]
        }).then(() => {
          router.go(-1);
          context.commit("clearPersonnelForm");
          context.commit("clearUserPersonnel", {
            fieldName: "userGroup"
          });
        });
      } else
        return Promise.resolve().then(() => {
          router.go(-1);
          context.commit("clearPersonnelForm");
          context.commit("clearUserPersonnel", {
            fieldName: "userGroup"
          });
          context.commit("clearUserPersonnel", {
            fieldName: "users"
          });
        });
    }
    //если новый пользователь
    if (!userPersonnel) {
      if (!selectedGroup) return Promise.reject();
      const userGroup = context.getters.getUserGroup;
      const userData = {
        phone_number: personnelCreate.phone_number
          .replace(/[^\d+]/g, "")
          .slice(0, 13),
        group: selectedGroup.id,
        user: personnelCreate.user,
        last_name: personnelCreate.last_name,
        middle_name: personnelCreate.middle_name
      };
      const user = (await createUserRequest({ data: userData })).data;
      await postAddUserToGroupRequest({
        id: user.id,
        data: { group: userGroup }
      });
      if (teachers) {
        const existTeachers = (await Axios.get(`/children-groups/${teachers}/`))
          .data.teachers;
        return Axios.patch(`/children-groups/${teachers}/`, {
          teachers: [...existTeachers, user.id]
        }).then(() => {
          router.go(-1);
          context.commit("clearPersonnelForm");
          context.commit("clearUserPersonnel", {
            fieldName: "userGroup"
          });
        });
      } else
        return Promise.resolve().then(() => {
          router.go(-1);
          context.commit("clearPersonnelForm");
          context.commit("clearUserPersonnel", {
            fieldName: "userGroup"
          });
        });
    }
  },

  // async vsCreateStuff(context, kindergartenId) {
  //   const personnelCreate = context.getters.getPersonnelCreate;
  //   const userId = context.getters.getUsers; // id пользователя
  //   const userGroupId = context.getters.getUserGroup; // Пользователи с бесплатным доступом к объекту
  //
  //   const roleGroups = (
  //     await getRoleGroupsRequest({
  //       query: {
  //         organization: kindergartenId || context.getters.getCurrentOrganization
  //       }
  //     })
  //   ).data.results;
  //
  //   const selectedGroup = roleGroups.find(
  //     el => el.role == personnelCreate.selectedRole
  //   );
  //
  //   return new Promise(resolve => {
  //     if (!userId) {
  //       context
  //         .dispatch("vsCreateNewUser", {
  //           userForm: {
  //             ...personnelCreate,
  //             group: selectedGroup.id
  //           }
  //         })
  //         .then(user => {
  //           context.dispatch("vsAddUserToGroup", {
  //             userId: user.id,
  //             groupId: userGroupId
  //           });
  //           resolve(user.id);
  //         });
  //     } else {
  //       context
  //         .dispatch("vsAddUserToGroup", {
  //           userId: userId,
  //           groupId: selectedGroup.id
  //         })
  //         .then(() => {
  //           context.dispatch("vsAddUserToGroup", {
  //             userId: userId,
  //             groupId: userGroupId
  //           });
  //           resolve(userId);
  //         });
  //     }
  //   });
  // },

  async vsCreateStuff(context, kindergartenId) {
    const personnelCreate = context.getters.getPersonnelCreate;
    // const userId = context.getters.getUsers; // id пользователя
    // const userGroupId = context.getters.getUserGroup; // Пользователи с бесплатным доступом к объекту

    const roleGroups = (
      await getRoleGroupsRequest({
        query: {
          organization: kindergartenId || context.getters.getCurrentOrganization
        }
      })
    ).data.results;

    let prom;
    prom = postAddPersonnelKindergtensToGroupRequest({
      id: getIdentificatorUserRole(),
      data: {
        additional_fields: [
          {
            id: localStorage.getItem("UserFieldId"),
            value: personnelCreate.user
          },
          {
            id: localStorage.getItem("CommentsFieldId"),
            value: personnelCreate.comments
          }
        ],
        phone_number: personnelCreate.phone_number
          .replace(/[^\d+]/g, "")
          .slice(0, 13),
        group: roleGroups.find(el => el.role == personnelCreate.selectedRole).id
      }
    });

    // prom.then(() => {
    //   router.go(-1);
    //   context.commit("clearCreateUserForm");
    // });
    return prom;
  },

  // async vsCreateStuff(context, kindergartenId) {
  //   const personnelCreate = context.getters.getPersonnelCreate;
  //   const userId = context.getters.getUsers; // id пользователя
  //   const userGroupId = context.getters.getUserGroup; // Пользователи с бесплатным доступом к объекту
  //
  //   const roleGroups = (
  //     await getRoleGroupsRequest({
  //       query: {
  //         organization: kindergartenId || context.getters.getCurrentOrganization
  //       }
  //     })
  //   ).data.results;
  //
  //   const selectedGroup = roleGroups.find(
  //     el => el.role == personnelCreate.selectedRole
  //   );
  //
  //   return new Promise(resolve => {
  //     if (!userId) {
  //       context
  //         .dispatch("vsCreateNewUser", {
  //           userForm: {
  //             ...personnelCreate,
  //             group: selectedGroup.id
  //           }
  //         })
  //         .then(user => {
  //           context.dispatch("vsAddUserToGroup", {
  //             userId: user.id,
  //             groupId: userGroupId
  //           });
  //           resolve(user.id);
  //         });
  //     } else {
  //       context
  //         .dispatch("vsAddUserToGroup", {
  //           userId: userId,
  //           groupId: selectedGroup.id
  //         })
  //         .then(() => {
  //           context.dispatch("vsAddUserToGroup", {
  //             userId: userId,
  //             groupId: userGroupId
  //           });
  //           resolve(userId);
  //         });
  //     }
  //   });
  // },

  async addTeacherToGroup(context, { groupId, teacherId }) {
    const existTeachers = (await Axios.get(`/children-groups/${groupId}/`)).data
      .teachers;
    return new Promise(resolve => {
      resolve(
        Axios.patch(`/children-groups/${groupId}/`, {
          teachers: [...existTeachers, teacherId]
        })
      );
    });
  },

  //создание пользователей через файл
  async uploadUsers(context) {
    const uploadForm = context.getters.getCreateUserFileForm;
    const formData = new FormData();
    formData.append("file", uploadForm.file);
    // formData.append("group", uploadForm.group);
    const createResult = uploadUserFileRequest({
      data: formData,
      id: uploadForm.group || context.getters.getUserGroup
    });

    createResult.then(() => {
      router.go(-1);
      context.commit("clearCreateUserForm");
    });
    return createResult;
  },
  //создание пользователей на обьекте через файл
  async uploadUsersObject(context) {
    const uploadForm = context.getters.getCreateUserFileForm;
    const formData = new FormData();
    formData.append("file", uploadForm.file);
    // formData.append("group", uploadForm.group);
    const createResult = uploadUserFileRequest({
      data: formData,
      id: uploadForm.group
    });

    createResult.then(() => {
      router.go(-1);
      context.commit("clearCreateUserForm");
    });
    return createResult;
  },

  async deleteArrUsers(context, { group_id, data }) {
    const prom = deleteArrUsersRequest({ group_id, data });
    return prom;
  }
};

const mutations = {
  setCurrentUser(state, { user }) {
    state.currentUserCreateObject = user;
  },
  clearCurrentUser(state) {
    state.currentUserCreateObject = null;
  },
  setCurrentUserKindergardens(state, { user }) {
    state.currentUserCreateKindergartens = user;
  },
  clearCurrentUserKindergardens(state) {
    state.currentUserCreateKindergartens = null;
  },
  clearCreateUserForm(state) {
    state.createUserForm = {
      phone_number: "",
      group: null,
      user: "",
      comments: ""
    };

    state.createUserFileForm = {
      file: null,
      group: null
    };
  },
  clearCreateUserObjectForm(state) {
    state.createUserOnObjectForm = {
      phone_number: "",
      user: "",
      house: "",
      houseNumber: "",
      carNumber: "",
      comments: ""
    };

    state.createUserFileForm = {
      file: null,
      group: null
    };
  },
  clearUserPersonnel(state) {
    state.userGroup = null;
    state.users = null;
  },
  clearPersonnelForm(state) {
    state.personnelCreate = {
      phone_number: null,
      selectedRole: 80,
      teacherGroup: "",
      user: "",
      comments: ""
    };
  },
  prepareEditUser(
    state,
    {
      last_name,
      middle_name,
      phone_number,
      first_name,
      additional_fields,
      is_phone_confirmed
    }
  ) {
    state.userEditForm.phone_number.value = phone_number;
    state.userEditForm.first_name.value = first_name;
    state.userEditForm.last_name.value = last_name;
    state.userEditForm.middle_name.value = middle_name;
    state.userEditForm.is_phone_confirmed.value = is_phone_confirmed;

    let result = new Map();
    additional_fields.forEach(el => result.set(el.name, el.value));

    state.userEditForm.user.value = result.get("Пользователь");
    state.userEditForm.comments.value = result.get("Комментарий");

    for (let obj in state.userEditForm) {
      state.userEditForm[obj].isEdit = false;
    }
  },

  prepareEditUserOnObject(
    state,
    { phone_number, is_phone_confirmed, additional_fields }
  ) {
    state.userEditOnObjectForm.phone_number.value = phone_number;

    let result = new Map();
    additional_fields.forEach(el => result.set(el.name, el.value));

    state.userEditOnObjectForm.user.value = result.get("Пользователь");
    state.userEditOnObjectForm.house.value = result.get("Дом/корпус");
    state.userEditOnObjectForm.houseNumber.value = result.get("Квартира/офис");
    state.userEditOnObjectForm.carNumber.value = result.get("Номер автомобиля");
    state.userEditOnObjectForm.comments.value = result.get("Комментарий");
    state.userEditOnObjectForm.is_phone_confirmed.value = is_phone_confirmed;

    for (let obj in state.userEditOnObjectForm) {
      state.userEditOnObjectForm[obj].isEdit = false;
    }
  },
  setPersonnelCreate(state, { fieldName, value }) {
    state.personnelCreate[fieldName] = value;
  },
  changeEditUserValue(state, { fieldName, value, id }) {
    state.userEditForm[fieldName].value = value;
    state.userEditForm[fieldName].isEdit = true;
    state.userEditForm[fieldName].id = id;
  },
  changeEditUserOnObjectValue(state, { fieldName, value, id }) {
    state.userEditOnObjectForm[fieldName].value = value;
    state.userEditOnObjectForm[fieldName].isEdit = true;
    state.userEditOnObjectForm[fieldName].id = id;
  },
  setCreateUserForm(state, { fieldName, value }) {
    Vue.set(state.createUserForm, fieldName, value);
  },
  setCreateUserObjectForm(state, { fieldName, value }) {
    Vue.set(state.createUserOnObjectForm, fieldName, value);
  },
  setCreateUserFileForm(state, { fieldName, value }) {
    Vue.set(state.createUserFileForm, fieldName, value);
  },
  setUsersList(state, { listName, users }) {
    state[listName] = users;
  },
  setUserInfo(state, { listName, users }) {
    state[listName].results = users;
  },
  clearUsersList(state, { listName }) {
    state[listName].results = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
